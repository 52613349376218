hr {
  border: none;
  border-bottom: 1px solid var(--gray-300);
  margin: 20px 0;
}

.pagination {
  list-style: none;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  &.isLoading {
    pointer-events: none;
    opacity: .5;
    min-height: unset;
    
    &:before {
      display: none;
    }
  }

  li {
    &:not(:last-child) {
      margin-right: 5px;
    }

    a {
      border: 1px solid var(--border-color);
      height: 32px;
      min-width: 32px;
      padding: 0 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      background-color: #fff;
      transition: .2s;
      color: var(--accent);

      &:hover {
        background-color: var(--accent-100);
      }
    }

    &.break-me, &.disabled {
      a {
        color: var(--gray-300);
        cursor: not-allowed;
      }
    }
    
    &.active {
      a {
        background-color: var(--accent);
        color: #fff;
        border-color: var(--accent);
      }
    }
  }
}

.isLoading {
  pointer-events: none;
  opacity: .5;
  position: relative;
  min-height: 100px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid var(--accent);
    border-left-color: transparent;
    animation: rotate .5s infinite linear;
    z-index: 99;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
.isLoading-white {
  pointer-events: none;
  position: relative;
  min-height: 100px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid white;
    border-left-color: transparent;
    animation: rotate .5s infinite linear;
    z-index: 99;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
.react-toast-notifications__container {
  z-index: 99999 !important;
}


.progress-text{
  color: #ffff;
  font-size: 32px;
  margin-top: 150px;
}
.progress-container{
  top: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-none{
    display: none;
  }
}

.overflow-div{
  overflow-x: auto;
}

.form-controller{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.block-controller{
  background: #FFFFFF;
  border: 1px solid #D9E3E7;
  border-radius: 16px;
  padding: 16px;
}

.controller-wrapper{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 20px;

  h2{
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 24px;
    color: #585757;
  }
}

.grid-column{
  grid-column: 2;
}

.filter-wrapper{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.filter-wrapper2{
  width: 100%;
  display: grid;
  grid-template-columns: 400px 400px 1fr 1fr;
  gap: 10px;
}

.filter-container{
  background: #FFFFFF;
  border-radius: 12px;
  padding: 18px 30px;

  h3{
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    color: #585757;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    span{
      margin-right: 10px;
    }
  }
}

.grid-auto{
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 25px;
}

.align-sefl{
  align-self: center;
}

.unvisible{
  display: none;
}

.red-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 2px 6px 4px 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 10px;
  top: -9px;
}

.dot-red{
  background-color:#fd6074;
}

.dot-green{
  background-color:#19b159;
}
@import "./fonts/icoMoon/style.css";
@import "./styles/breakpoints";
@import "./fonts/fonts";
@import "./styles/colors";
@import "./styles/grid";
@import "./styles/typography";
@import "./styles/components";
@import "./styles/margin";

html {
  body {
    margin: 0;
    color: var(--text-default);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    line-height: unset;
    overflow-y: scroll;
    background-color: #F6F8F9;
  }
}

*, ::after, ::before {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

img, video {
  max-width: 100%;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  footer {
    margin-top: auto;
  }
}

.red-none{
    color: var(--error);
}
.green-yes{
    color: var(--success);
}
.mr-20{
    padding-right: 20px;
}

.link-open{
    cursor: pointer;
    transition: .3s;
    color: var(--accent);
    &:hover{
        color: var(--accent-hover);
    }
}
.link-accent{
    color: var(--accent);
}

.mr-10{
    margin-right: 10px;
}
.td-span{
    font-size: 14px;
    padding: 7px 16px;
    line-height: 20px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 4px;

    img{
        width: 22px;
        height: 22px;
        filter: grayscale(1) brightness(1000%);
    }
}
.indication-table{
    width: fit-content;
}
.back-white{
    background-color: #ffff;
    border-radius: 16px;
    border: 2px solid #2B93FA;
    margin-bottom: 8px;
    padding-bottom: 8px;
}

.color-accent{
    color: var(--accent) !important;
 }

 .wd-10{
    width: 5px;
 }

.icon-copy{
    margin-left: 5px;
    cursor: pointer;
    &:hover{
        color: var(--accent) !important;
    }
}
.ban-span{
    .td-span{
        width: fit-content;
    }
}

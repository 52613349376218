@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-SemiBold.eot');
  src: url('./Inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-SemiBold.woff') format('woff'),
  url('./Inter/Inter-SemiBold.ttf') format('truetype'),
  url('./Inter/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-Thin.eot');
  src: url('./Inter/Inter-Thin.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-Thin.woff') format('woff'),
  url('./Inter/Inter-Thin.ttf') format('truetype'),
  url('./Inter/Inter-Thin.svg#Inter-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-Light.eot');
  src: url('./Inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-Light.woff') format('woff'),
  url('./Inter/Inter-Light.ttf') format('truetype'),
  url('./Inter/Inter-Light.svg#Inter-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-ExtraBold.eot');
  src: url('./Inter/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-ExtraBold.woff') format('woff'),
  url('./Inter/Inter-ExtraBold.ttf') format('truetype'),
  url('./Inter/Inter-ExtraBold.svg#Inter-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-Regular.eot');
  src: url('./Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-Regular.woff') format('woff'),
  url('./Inter/Inter-Regular.ttf') format('truetype'),
  url('./Inter/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-Medium.eot');
  src: url('./Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-Medium.woff') format('woff'),
  url('./Inter/Inter-Medium.ttf') format('truetype'),
  url('./Inter/Inter-Medium.svg#Inter-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-Bold.eot');
  src: url('./Inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-Bold.woff') format('woff'),
  url('./Inter/Inter-Bold.ttf') format('truetype'),
  url('./Inter/Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-Black.eot');
  src: url('./Inter/Inter-Black.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-Black.woff') format('woff'),
  url('./Inter/Inter-Black.ttf') format('truetype'),
  url('./Inter/Inter-Black.svg#Inter-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-ExtraLight.eot');
  src: url('./Inter/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('./Inter/Inter-ExtraLight.woff') format('woff'),
  url('./Inter/Inter-ExtraLight.ttf') format('truetype'),
  url('./Inter/Inter-ExtraLight.svg#Inter-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
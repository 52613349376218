@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?igajr6');
  src:  url('fonts/icomoon.eot?igajr6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?igajr6') format('truetype'),
    url('fonts/icomoon.woff?igajr6') format('woff'),
    url('fonts/icomoon.svg?igajr6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fi-eye:before {
  content: "\e900";
}
.icon-tasks:before {
  content: "\e901";
}
.icon-checkbox-on:before {
  content: "\e904";
}
.icon-radio-off:before {
  content: "\e902";
}
.icon-radio-on:before {
  content: "\e903";
}
.icon-refresh:before {
  content: "\e908";
}
.icon-checkbox-off:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-dashboard:before {
  content: "\e907";
}
.icon-filter:before {
  content: "\e909";
}
.icon-folder:before {
  content: "\e90a";
}
.icon-list:before {
  content: "\e90b";
}
.icon-marker:before {
  content: "\e90d";
}
.icon-pencil:before {
  content: "\e90c";
}
.icon-physics:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e90f";
}
.icon-receipt:before {
  content: "\e910";
}
.icon-tool-crop:before {
  content: "\e912";
}
.icon-trash:before {
  content: "\e911";
}
.icon-upload:before {
  content: "\e913";
}
.icon-users:before {
  content: "\e915";
}
.icon-apps:before {
  content: "\e914";
}
.icon-badge:before {
  content: "\e916";
}
.icon-bell:before {
  content: "\e917";
}
.icon-briefcase:before {
  content: "\e91d";
}
.icon-chevron-right:before {
  content: "\e918";
}
.icon-chevron-down:before {
  content: "\e919";
}
.icon-chevron-left:before {
  content: "\e91a";
}
.icon-chevron-up:before {
  content: "\e91b";
}
.icon-clip:before {
  content: "\e91c";
}
.icon-download:before {
  content: "\e91e";
}
.icon-approve:before {
  content: "\e91f";
}
.icon-cancel:before {
  content: "\e920";
}
.icon-check:before {
  content: "\e921";
}
.icon-settings:before {
  content: "\e922";
}
.icon-model-change:before {
  content: "\e923";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-volume-medium:before {
  content: "\ea27";
}
.icon-volume-mute:before {
  content: "\ea2a";
}
.icon-robot:before {
    content: "\e92e";
}
.icon-multi-users:before {
    content: "\e92f";
}
.icon-noun-picture-1166736-11:before {
    content: "\e92c";
    color: #b3b3b3;
  }
  .icon-noun-picture-1166736-2:before {
    content: "\e925";
  }
  .icon-noun-wifi-5321212-2:before {
    content: "\e926";
  }
  .icon-noun-cold-water-3968577-3:before {
    content: "\e927";
  }
  .icon-noun-no-wifi-5321212-1:before {
    content: "\e928";
  }
  .icon-noun-picture-1166736-1-1:before {
    content: "\e929";
  }
  .icon-noun-picture-1166736-1:before {
    content: "\e92a";
  }
  .icon-noun-picture-1166736-2-1:before {
    content: "\e92b";
  }
  .icon-noun-cold-water-3968577-2:before {
    content: "\e924";
}
.icon-copy:before {
    content: "\e92d";
}
  
$gap: 20px;

.container {
  //max-width: calc(1080px + 48px);
  max-width: 100%;
  margin: 0 auto;
  //padding: 0 24px;
  width: 100%;

  @media screen and (max-width: $laptop-m) {
    max-width: calc(992px + 48px);
  }

  @media screen and (max-width: $laptop-s) {
    max-width: calc(768px + 48px);
  }

  @media screen and (max-width: $tablet) {
    max-width: calc(480px + 48px);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gap/2;
  margin-right: -$gap/2;

  &--multiline {
    margin: -$gap/2;

    &>[class^='col'] {
      padding: $gap/2;
    }

    &.row--margin-8 {
      margin: -4px;

      &>[class^='col'] {
        padding: 4px;
      }
    }
  }

  &>[class^='col'] {
    padding-left: $gap/2;
    padding-right: $gap/2;
  }

  &.column-reverse-sm {
    @media screen and (max-width: $laptop-s) {
      flex-direction: column-reverse;
    }
  }
}

[class^='col'] {
  flex-shrink: 0;
  width: 100%;
}

.col-auto {
  width: unset;
  max-width: 100%;
}

.col-grow-1 {
  flex-grow: 1;
}

.col-xs-1 {width: 100%/12*1;}
.col-xs-2 {width: 100%/12*2;}
.col-xs-3 {width: 100%/12*3;}
.col-xs-4 {width: 100%/12*4;}
.col-xs-5 {width: 100%/12*5;}
.col-xs-6 {width: 100%/12*6;}
.col-xs-7 {width: 100%/12*7;}
.col-xs-8 {width: 100%/12*8;}
.col-xs-9 {width: 100%/12*9;}
.col-xs-10 {width: 100%/12*10;}
.col-xs-11 {width: 100%/12*11;}
.col-xs-12 {width: 100%/12*12;}

.row-xs-1 {&>[class^='col'] {width: calc(100%/1);}}
.row-xs-2 {&>[class^='col'] {width: calc(100%/2);}}
.row-xs-3 {&>[class^='col'] {width: calc(100%/3);}}
.row-xs-4 {&>[class^='col'] {width: calc(100%/4);}}
.row-xs-5 {&>[class^='col'] {width: calc(100%/5);}}
.row-xs-6 {&>[class^='col'] {width: calc(100%/6);}}
.row-xs-7 {&>[class^='col'] {width: calc(100%/7);}}
.row-xs-8 {&>[class^='col'] {width: calc(100%/8);}}
.row-xs-9 {&>[class^='col'] {width: calc(100%/9);}}
.row-xs-10 {&>[class^='col'] {width: calc(100%/10);}}
.row-xs-11 {&>[class^='col'] {width: calc(100%/11);}}
.row-xs-12 {&>[class^='col'] {width: calc(100%/12);}}

@media screen and (min-width: $tablet + 1) {
  .col-sm-1 {width: 100%/12*1;}
  .col-sm-2 {width: 100%/12*2;}
  .col-sm-3 {width: 100%/12*3;}
  .col-sm-4 {width: 100%/12*4;}
  .col-sm-5 {width: 100%/12*5;}
  .col-sm-6 {width: 100%/12*6;}
  .col-sm-7 {width: 100%/12*7;}
  .col-sm-8 {width: 100%/12*8;}
  .col-sm-9 {width: 100%/12*9;}
  .col-sm-10 {width: 100%/12*10;}
  .col-sm-11 {width: 100%/12*11;}
  .col-sm-12 {width: 100%/12*12;}

  .row-sm-1 {&>[class^='col'] {width: calc(100%/1);}}
  .row-sm-2 {&>[class^='col'] {width: calc(100%/2);}}
  .row-sm-3 {&>[class^='col'] {width: calc(100%/3);}}
  .row-sm-4 {&>[class^='col'] {width: calc(100%/4);}}
  .row-sm-5 {&>[class^='col'] {width: calc(100%/5);}}
  .row-sm-6 {&>[class^='col'] {width: calc(100%/6);}}
  .row-sm-7 {&>[class^='col'] {width: calc(100%/7);}}
  .row-sm-8 {&>[class^='col'] {width: calc(100%/8);}}
  .row-sm-9 {&>[class^='col'] {width: calc(100%/9);}}
  .row-sm-10 {&>[class^='col'] {width: calc(100%/10);}}
  .row-sm-11 {&>[class^='col'] {width: calc(100%/11);}}
  .row-sm-12 {&>[class^='col'] {width: calc(100%/12);}}
}

@media screen and (min-width: $laptop-s + 1) {
  .col-md-1 {width: 100%/12*1;}
  .col-md-2 {width: 100%/12*2;}
  .col-md-3 {width: 100%/12*3;}
  .col-md-4 {width: 100%/12*4;}
  .col-md-5 {width: 100%/12*5;}
  .col-md-6 {width: 100%/12*6;}
  .col-md-7 {width: 100%/12*7;}
  .col-md-8 {width: 100%/12*8;}
  .col-md-9 {width: 100%/12*9;}
  .col-md-10 {width: 100%/12*10;}
  .col-md-11 {width: 100%/12*11;}
  .col-md-12 {width: 100%/12*12;}

  .row-md-1 {&>[class^='col'] {width: calc(100%/1);}}
  .row-md-2 {&>[class^='col'] {width: calc(100%/2);}}
  .row-md-3 {&>[class^='col'] {width: calc(100%/3);}}
  .row-md-4 {&>[class^='col'] {width: calc(100%/4);}}
  .row-md-5 {&>[class^='col'] {width: calc(100%/5);}}
  .row-md-6 {&>[class^='col'] {width: calc(100%/6);}}
  .row-md-7 {&>[class^='col'] {width: calc(100%/7);}}
  .row-md-8 {&>[class^='col'] {width: calc(100%/8);}}
  .row-md-9 {&>[class^='col'] {width: calc(100%/9);}}
  .row-md-10 {&>[class^='col'] {width: calc(100%/10);}}
  .row-md-11 {&>[class^='col'] {width: calc(100%/11);}}
  .row-md-12 {&>[class^='col'] {width: calc(100%/12);}}
}

@media screen and (min-width: $laptop-m + 1) {
  .col-lg-1 {width: 100%/12*1;}
  .col-lg-2 {width: 100%/12*2;}
  .col-lg-3 {width: 100%/12*3;}
  .col-lg-4 {width: 100%/12*4;}
  .col-lg-5 {width: 100%/12*5;}
  .col-lg-6 {width: 100%/12*6;}
  .col-lg-7 {width: 100%/12*7;}
  .col-lg-8 {width: 100%/12*8;}
  .col-lg-9 {width: 100%/12*9;}
  .col-lg-10 {width: 100%/12*10;}
  .col-lg-11 {width: 100%/12*11;}
  .col-lg-12 {width: 100%/12*12;}

  .row-lg-1 {&>[class^='col'] {width: calc(100%/1);}}
  .row-lg-2 {&>[class^='col'] {width: calc(100%/2);}}
  .row-lg-3 {&>[class^='col'] {width: calc(100%/3);}}
  .row-lg-4 {&>[class^='col'] {width: calc(100%/4);}}
  .row-lg-5 {&>[class^='col'] {width: calc(100%/5);}}
  .row-lg-6 {&>[class^='col'] {width: calc(100%/6);}}
  .row-lg-7 {&>[class^='col'] {width: calc(100%/7);}}
  .row-lg-8 {&>[class^='col'] {width: calc(100%/8);}}
  .row-lg-9 {&>[class^='col'] {width: calc(100%/9);}}
  .row-lg-10 {&>[class^='col'] {width: calc(100%/10);}}
  .row-lg-11 {&>[class^='col'] {width: calc(100%/11);}}
  .row-lg-12 {&>[class^='col'] {width: calc(100%/12);}}
}



.hidden-xs {
  @media screen and (max-width: $tablet) {
    display: none !important;
  }
}

.hidden-sm {
  @media screen and (min-width: $tablet + 1) and (max-width: $laptop-s) {
    display: none !important;
  }
}

.hidden-md {
  @media screen and (min-width: $laptop-s + 1) and (max-width: $laptop-m) {
    display: none !important;
  }
}

.hidden-lg {
  @media screen and (min-width: $laptop-m + 1) {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mob-100 {
  @media screen and (max-width: $mobile-l) {
    width: 100%;
  }
}

.align-items-center {
  align-items: center;
}

.align-items-bottom {
  align-items: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
:root {
  //--accent: #047884;
  --accent: #1FA2FF;
  //--accent-hover: #00606c;
  --accent-hover: #0b80d4;
  --accent-100: #ddebed;
  --warning: #F2AC57;
  --warning-hover: #d98c30;
  --warning-100: #ffe7c2;
  --success: #14A38B;
  --success-hover: #079076;
  --blue: #146aa3;
  --blue-hover: #0d5a8b;
  --blue-100: #b8d2e0;
  --error: #FF7171;
  --error-100: #fff4f4;
  --error-hover: #d24e4e;
  //--primary: #005C66;
  --primary: #1FA2FF;
  //--secondary: #37AAB6;
  --secondary: #1FA2FF;
  //--secondary-hover: #138e9a;
  --secondary-hover: #0bb6d4;
  //--gradient: linear-gradient(153.69deg, #37AAB6 0%, #047884 48.96%, #005C66 100%);
  --gradient:  #1FA2FF;

  --text-default: #333;
  
  --gray-100: #F4F5F7;
  --gray-200: #EEEFF4;
  --gray-300: #D9DBE1;
  --gray-400: #969BAB;
  --gray-700: #6C6D6D;
  --gray-800: #474A57;
  --gray-900: #18191F;

  --shadow: 0px 5px 20px rgba(107, 107, 107, 0.05);
}
.ReactModal__Overlay {
  overflow-y: auto;
  text-align: center;
  padding: 20px;
  z-index: 1000;
  background-color: rgba(0,0,0,0.5) !important;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.ReactModal__Content {
  text-align: left;
}

.ReactModal__Content--custom {
  min-width: 300px;
  width: unset;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  outline: none !important;
}